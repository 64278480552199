<template>
    <border-box id="box4">
        <div class="panel">
            <div>运行热力图</div>
            <el-button type="primary" size="mini" @click="showHeatMap">查看</el-button>
            <Modal footer-hide :mask="true" :mask-closable="false" :scrollable="true" v-model="heatMapShow" width="80" class="model">
                <div ref="chartContainer" style="height: 100%; ;padding: 0 40px;">
                    <Form :label-width="125" label-position="right">
                        <Row>
                            <Col :xs="12" :sm="12" :md="12" :lg="12">
                            <FormItem label="筛选时间">
                                <DatePicker @on-ok="heatmapDateOK" @on-clear="heatmapDateClear" @on-change="changeHeatmapDate" format="yyyy-MM-dd" :value="[heatmapForm.startDate,heatmapForm.endDate]" clearable placeholder="请选择时间段" style="width:98%" type="datetimerange"></DatePicker>
                            </FormItem>
                            </Col>
                            <Col :xs="12" :sm="12" :md="12" :lg="12">
                            <div style="height:32px; line-height:32px;text-align:right;">{{this.optionHeatMap.title.text}}</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col :xs="24" :sm="24" :md="24" :lg="24">
                            <Card>
                                <highcharts class="highcharts" v-if="optionHeatMap.series[0].data.length !== 0" :options="optionHeatMap">
                                </highcharts>
                                <p class="nodata" v-if="optionHeatMap.series[0].data.length === 0">
                                    <svg-icon :icon-class="'encourage'" class="svg-icon_home_no_data" /><br>
                                    没有热力图数据
                                </p>
                                </p>
                            </Card>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Modal>
        </div>
    </border-box>
</template>
<script>
import { getElevatorHeatMapNewStatistics, getElevatorHeatMapStatistics } from '@/api/elevator/index.js'
import Moment from 'moment'
import 'moment/locale/zh-cn'
Moment.locale('zh-cn')

import Highcharts from 'highcharts'
import HeatMap from 'highcharts/modules/heatmap'
HeatMap(Highcharts)
// import BorderBox from '../../../components/BorderBox'
export default {
    data() {
        return {

            //热力图
            heatmapForm: {
                startDate: Moment()
                    .subtract(7, 'days')
                    .format('YYYY-MM-DD'),
                endDate: Moment().format('YYYY-MM-DD'),
                compireCodes: '',
            },
            selCodes: '',
            compireFlag: false,
            heatMapShow: false,
            // 梯楼层热力图
            optionHeatMap: {
                chart: {
                    type: 'heatmap',
                    marginTop: 40,
                    marginBottom: 80,
                    plotBorderWidth: 1,
                    height: 600
                },
                title: {
                    text: '楼层停靠热力图'
                },
                xAxis: {
                    categories: []
                },
                yAxis: {
                    categories: [],
                },
                colorAxis: {
                    min: 0,
                    minColor: '#FFFFFF',
                    maxColor: '#2d8cf0'
                },
                credits: {
                    enabled: false // 禁用版权信息
                },
                legend: {
                    align: 'right',
                    layout: 'vertical',
                    margin: 0,
                    verticalAlign: 'top',
                    y: 25,
                    symbolHeight: 280
                },
                tooltip: {
                    formatter: function() {
                        return '<b>' + this.series.xAxis.categories[this.point.x] + '在<b>' + this.series.yAxis.categories[this.point.y] + '</b>' + '</b> 停 <br><b>' +
                            this.point.value + '</b> 次 <br>'
                    }
                },
                series: [{
                    name: 'Sales per employee',
                    borderWidth: 1,
                    data: [],
                    dataLabels: {
                        enabled: true,
                        color: '#000000'
                    }
                }]
            }
        }
    },
    components: {
        // BorderBox,
    },
    methods: {
        getInfoBy(elevatorCode, registorNum) {
            //TO-DO
        },
        updateByInfo(info) {
            this.response = info
            this.optionHeatMap.title.text = `${info.v_elevator_name}电梯停靠热力图`
        },
        showHeatMap() {
            if (!this.response.v_elevator_code) return
            this.heatMapShow = true
            this.heatmapForm.compireCodes = this.response.v_elevator_code
            this.updateHeatmap(this.heatmapForm.startDate, this.heatmapForm.endDate, this.response.v_elevator_code)
        },
        updateHeatmap(startDate, endDate, codes) {
            let that = this
            getElevatorHeatMapStatistics({ startDate: startDate, endDate: endDate, elevatorCode: codes }).then(res => {
                const dateArr = []
                const codesArr = []
                const floorArr = []
                const xyPoints = []
                const hashMap = {}

                if (Array.isArray(res.info) && res.info.length > 0) {
                    if (that.heatmapForm.compireCodes.split(',').length > 1) { //多梯
                        const elevators = res.info
                        elevators.forEach(function(eObj, idx) {
                            if (eObj.elevatorCode && eObj.elevatorCode.length > 0)
                                codesArr.push(eObj.elevatorCode)
                            if (eObj.floorNumberCountInfo && Array.isArray(eObj.floorNumberCountInfo)) {
                                eObj.floorNumberCountInfo.forEach(function(fObj, idx) {
                                    if (floorArr.indexOf(fObj.floorNumber) < 0)
                                        floorArr.push(fObj.floorNumber)
                                    let total = fObj.countDateInfo.reduce(function(pre, cur) {
                                        return cur.countStop + pre
                                    }, 0)
                                    hashMap[eObj.elevatorCode + '_' + fObj.floorNumber] = total
                                })
                            }
                        })
                        floorArr.sort(function(a, b) {
                            return a > b ? 1 : -1
                        })

                        for (let i = 0; i < codesArr.length; i++) {
                            for (let j = 0; j < floorArr.length; j++) {
                                let count = hashMap[codesArr[i] + '_' + floorArr[j]]
                                xyPoints.push([i, j, count > 0 ? count : 0])
                            }
                        }
                        this.optionHeatMap.series[0].data = xyPoints
                        this.optionHeatMap.xAxis.categories = codesArr
                        this.optionHeatMap.xAxis.title = '电梯编号'
                        this.optionHeatMap.yAxis.categories = floorArr.map(function(obj) {
                            return obj + 'F'
                        })
                        this.optionHeatMap.yAxis.title = '楼层'
                    } else { //单梯
                        const obj = res.info[0]
                        obj.floorNumberCountInfo.forEach(function(fObj, idx) {
                            fObj.countDateInfo.forEach(function(cObj, idx) {
                                if (dateArr.indexOf(cObj.countDate) < 0)
                                    dateArr.push(cObj.countDate)
                                hashMap[fObj.floorNumber + '_' + cObj.countDate] = cObj.countStop
                            })
                            floorArr.push(fObj.floorNumber)
                        })
                        dateArr.sort(function(a, b) {
                            return a > b ? 1 : -1
                        })

                        for (let i = 0; i < dateArr.length; i++) {
                            for (let j = 0; j < floorArr.length; j++) {
                                let count = hashMap[floorArr[j] + '_' + dateArr[i]]
                                xyPoints.push([i, j, count > 0 ? count : 0])
                            }
                        }
                        this.optionHeatMap.series[0].data = xyPoints
                        this.optionHeatMap.xAxis.categories = dateArr
                        this.optionHeatMap.xAxis.title = '日期'
                        this.optionHeatMap.yAxis.categories = floorArr.map(function(obj) {
                            return obj + 'F'
                        })
                        this.optionHeatMap.yAxis.title = '楼层'
                    }
                    let height = 300 + 30*floorArr.length
                    this.optionHeatMap.chart.height = height<600?600:height
                } else {
                    this.optionHeatMap.series[0].data = []
                    this.optionHeatMap.xAxis.categories = []
                    this.optionHeatMap.yAxis.categories = []
                }
            })
        },
        heatmapDateOK() {
            this.updateHeatmap(this.heatmapForm.startDate, this.heatmapForm.endDate, this.heatmapForm.compireCodes)
        },
        heatmapDateClear() {
            this.heatmapForm.endDate = Moment().format('YYYY-MM-DD')
            this.heatmapForm.startDate = Moment().subtract(5, 'days').format('YYYY-MM-DD')
            this.updateHeatmap(this.heatmapForm.startDate, this.heatmapForm.endDate, this.heatmapForm.compireCodes)

        },
        changeHeatmapDate(e) {
            console.log(e)
            if (e.length == 2) {
                this.heatmapForm.startDate = e[0]
                this.heatmapForm.endDate = e[1]
            }
        },
    }
}
</script>
<style lang="scss" scoped>
@import "@/common/styles/handle.scss";

#box4 {
    height: 100%;
}

.panel {
    // border: 1px solid #fff;
    height: 100%;
    font-size: 16px;
    font-weight: 600;
    color: #383C4B;
    padding: 0 16px;
    display: flex;
    padding-top: 5px;
    align-items: center;
    justify-content: space-between;
    @include background_color(singleMonitorHeatMapBgColor);
    @include font_color(singleMonitorHeatMapTextColor);
    @include border_color(panelBorderColor);
    @include background_color(panelBgColor);
}
::v-deep .ivu-input {
    background-color:#fff!important;
    color:#000!important;
}

::v-deep .ivu-select-dropdown {
    background-color:#fff!important;
}
</style>